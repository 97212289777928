export interface User {
  id: string;
  phone: string;
  name: string;
  email: string;
  isPlantExists: boolean;
  androidVersion: string;
  appVersion: string;
  mobileMake: string;
  mobileModel: string;
  isBMSPlantExists: boolean;
  active: boolean;
  secondryRoles: number[];
  user_type: UserType;
  secondary_roles: number[];
  installer_id: string;
  user_image: string;
  userCategory: UserCategory;
}
export enum UserType {
  CUSTOMER = 1,
  ADMIN = 2,
  SYSTEM_INTEGRATOR = 3,
  SECONDARY_USER = 4,
  SERVICE_ENGINEER = 5,
  THIRD_PARTY_INTEGRATOR = 6,
}

export enum UserCategory {
    FREE = 0,
    STANDARD = 1,
    PREMIUM = 2,
    ELITE = 3,
  }
